import React from "react";

import { SelectField, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { ItemUnitMetric } from "../Sku";

export const SkuPortionsGroup = ({ sku, handleChange }) => (
  <GridWrapper>
    <GridItem size={{ xs: "1/2" }} style={{ margin: "1rem 0 0 0" }}>
      <SelectField
        name="itemUnitMetric"
        label="Unit Metric"
        value={sku ? sku.itemUnitMetric : "EACH"}
        options={ItemUnitMetric}
        onChange={(e) => handleChange(e)}
        required
      />
    </GridItem>
    <GridItem size={{ xs: "1/2" }} style={{ margin: "1rem 0 0 0" }}>
      <TextInputField
        name="portionsPerMetric"
        label="Portions per metric"
        placeholder="Portions per metric"
        onChange={(e) => handleChange(e)}
        required
        value={sku.portionsPerMetric || ""}
      />
    </GridItem>
  </GridWrapper>
);
