import PropTypes from "prop-types";
import React from "react";

import { TableCell, TableRow } from "@jsluna/table";

const TransactionRow = ({ transaction }) => {
  // const isCredit = () => {
  //   const creditTransactions = ["NORMAL", "BASERATE", "BONUS"];
  //   return creditTransactions.includes(transaction.transactionType);
  // };

  const description = () => {
    switch (transaction.transactionType) {
      case "NORMAL":
        return "Normal";
      case "BASERATE":
        return "Baserate";
      case "BONUS":
        return transaction.descriptionText;
      default:
        return "Unknown";
    }
  };

  const location = () => (transaction.location ? transaction.location : "N/A");

  return (
    <TableRow>
      <TableCell width="1">{transaction.date}</TableCell>
      <TableCell width="3">{transaction.descriptionText}</TableCell>
      <TableCell width="3">{location()}</TableCell>
      <TableCell width="1">
        <div style={{ fontSize: "large", color: "#2c662d" }}>
          {transaction.totalPointsDisplayValue} points
        </div>
        <br />
        <div style={{ fontSize: "small", color: "#2c662d" }}>
          {description()}
        </div>
      </TableCell>
    </TableRow>
  );
};

TransactionRow.propTypes = {
  transaction: PropTypes.shape({
    date: PropTypes.string.isRequired,
    location: PropTypes.string,
    transactionType: PropTypes.oneOf(["NORMAL", "BASERATE", "BONUS"])
      .isRequired,
    totalPointsDisplayValue: PropTypes.string.isRequired,
    reason: PropTypes.string,
    additionalInformation: PropTypes.string,
    details: PropTypes.shape({
      campaignName: PropTypes.string.isRequired,
      pointsDisplayValue: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TransactionRow;
