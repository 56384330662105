import React, { useState } from "react";
import { toast } from "react-toastify";

import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  FilledButton,
  TextButton,
} from "@jsluna/button";
import { Label } from "@jsluna/form";
import { Delete } from "@jsluna/icons";
import { Modal, ModalHeading } from "@jsluna/react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@jsluna/table";

import CustomerClient from "../../services/CustomerClient";
import "./Offers.scss";

const customerClient = new CustomerClient();

const OffersView = ({ offers, customer, isOA, reloadCustomer }) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState({});

  const COLUMN_NAMES = {
    COLUMN_OFFER: "Offer",
    COLUMN_POINTS: "Points",
    COLUMN_VALID_FROM: "Valid From",
    COLUMN_EXPIRED: "Expires",
    COLUMN_STATE: "State",
  };

  const columns = Object.values(COLUMN_NAMES);

  const formattedDate = (dateString) => {
    let date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const openConfimationDialog = (offer) => {
    setToggleModal(!toggleModal);
    setSelectedOffer(offer || {});
  };

  const cancelOffer = () => {
    const offerId = selectedOffer && selectedOffer.id;
    const customerId = customer && customer.id;
    customerClient
      .cancelCustomerOffer(customerId, offerId)
      .then((res) => {
        if (res.status === 200) {
          reloadCustomer();
          setToggleModal(false);
          toast.success("Action completed successfully");
        }
      })
      .catch((err) => {
        toast.error(err.message || "An error occurred");
      });
  };

  return offers.length > 0 ? (
    <React.Fragment>
      <TableContainer responsive>
        <TableHeader>
          <TableHeaderRow>
            {columns.map((column) => (
              <TableHeaderCell key={column}>{column}</TableHeaderCell>
            ))}
            {!isOA && <TableHeaderCell>Cancel</TableHeaderCell>}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {offers.map((offer, key) => (
            <TableRow key={key}>
              <TableCell label={COLUMN_NAMES.COLUMN_OFFER}>
                <div className="image-description">
                  <img alt="" src={offer.imgUrl} className="small-image" />
                  <div className="content">
                    <div className="title">{offer.title}</div>
                    <div className="sub-header">{offer.description}</div>
                  </div>
                </div>
              </TableCell>
              <TableCell label={COLUMN_NAMES.COLUMN_POINTS}>
                {offer.points}
              </TableCell>
              <TableCell label={COLUMN_NAMES.COLUMN_VALID_FROM}>
                {formattedDate(offer.validFrom)}
              </TableCell>
              <TableCell label={COLUMN_NAMES.COLUMN_EXPIRED}>
                {formattedDate(offer.expires)}
              </TableCell>
              <TableCell label={COLUMN_NAMES.COLUMN_STATE}>
                {offer.state}
              </TableCell>
              {!isOA && (
                <TableCell
                  label={COLUMN_NAMES.COLUMN_STATUS}
                  style={{ cursor: "pointer" }}
                  onClick={() => openConfimationDialog(offer)}
                >
                  <Delete />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      <Modal
        fullScreen
        restrictClose
        alert
        handleClose={() => openConfimationDialog()}
        open={toggleModal}
        headingId="dialog-modal"
        className="custom-modal"
      >
        <ModalHeading>Confirm Action!</ModalHeading>
        <Label>Are you sure?</Label>
        <ButtonGroupWrapper actionbar>
          <ButtonGroupPrimary>
            <TextButton onClick={() => openConfimationDialog()}>
              Cancel
            </TextButton>
            <FilledButton
              className="ln-u-margin-left"
              onClick={() => cancelOffer()}
            >
              Delete
            </FilledButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </React.Fragment>
  ) : (
    <span>No offers.</span>
  );
};

export default OffersView;
