import React from "react";

import { Label, TextInput } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Scan } from "@jsluna/icons";

export const PodAccessibilityTextInput = ({ pod, handleChange }) => (
  <div>
    <br />
    <span>
      <strong>
        <Scan />
        &nbsp;&nbsp;<Label>Feature Image</Label>
      </strong>
    </span>
    <GridWrapper>
      <GridItem style={{ margin: "1rem 0 0 0" }}>
        <Label>Accessibility Text</Label>
        <TextInput
          value={pod.body && pod.body.data && pod.body.data.accessibilityText}
          name="accessibilityText"
          placeholder="Accessibility Text"
          onChange={(e) => {
            handleChange(e, "body");
          }}
        />
      </GridItem>
    </GridWrapper>
  </div>
);
