import PropTypes from "prop-types";
import React from "react";

const CustomerPointsManager = ({ customerBalance }) => {
  if (customerBalance === undefined) {
    return (
      <div>
        <span>Balance from Nectar unavailable</span>
      </div>
    );
  }

  return (
    <div>
      <div>
        <h4>
          <b>Points balance: {customerBalance}</b>
        </h4>
      </div>
    </div>
  );
};

CustomerPointsManager.propTypes = {
  customerBalance: PropTypes.number,
};

export default CustomerPointsManager;
