import React from "react";

import { SelectField, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { Category } from "../Sku";

export const SkuDetailsGroup = ({ sku, handleChange, isNewSku }) => (
  <GridWrapper>
    <GridItem size={{ xs: "1/2" }} style={{ margin: "1rem 0 0 0" }}>
      <TextInputField
        name="skuId"
        label="Id"
        placeholder="Enter Sku id"
        value={sku.skuId || ""}
        onChange={(e) => handleChange(e)}
        readOnly={!isNewSku}
        required
      />
    </GridItem>
    <GridItem size={{ xs: "1/2" }} style={{ margin: "1rem 0 0 0" }}>
      <SelectField
        name="category"
        label="Product category"
        value={sku ? sku.category : "fruit"}
        options={Category}
        onChange={(e) => handleChange(e)}
        required
      />
    </GridItem>
  </GridWrapper>
);
