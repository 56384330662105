import React, { useCallback, useEffect, useState } from "react";

import ProductClient from "../../services/ProductClient";
import PagedTable from "./PagedTable";
import ProductTable from "./ProductTable";

const productClient = new ProductClient();
const WAIT_INTERVAL = 500;

const ProductSearchAspect = ({
  searchTerm: initialSearchTerm,
  searchFilters: initialSearchFilters,
  selectedProducts,
  onProductSelect,
  children,
}) => {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm || "");
  const [searchFilters, setSearchFilters] = useState(
    initialSearchFilters || {}
  );

  const searchProducts = useCallback(
    (page) => {
      if (searchTerm.length > 1 || searchFilters.category) {
        productClient
          .searchProducts(searchTerm, page, searchFilters)
          .then(({ data }) => {
            setProducts(data.products);
            setTotalPages(data.totalPages);
            setPage(data.currentPage);
          });
      }
    },
    [searchTerm, searchFilters]
  );

  useEffect(() => {
    if (initialSearchTerm !== searchTerm) {
      setSearchTerm(initialSearchTerm);
      setTimeout(() => {
        if (initialSearchTerm === searchTerm) {
          searchProducts(0);
        }
      }, WAIT_INTERVAL);
    }
  }, [initialSearchTerm, searchTerm, searchProducts]);

  useEffect(() => {
    if (initialSearchFilters !== searchFilters) {
      setSearchFilters(initialSearchFilters);
      searchProducts(0);
    }
  }, [initialSearchFilters, searchFilters, searchProducts]);

  const pagingNavigator = (amount) => {
    if (page + amount < totalPages && page + amount >= 0) {
      setTimeout(() => {
        setPage(page + amount);
        searchProducts(page + amount);
      }, WAIT_INTERVAL);
    }
  };

  const handleProductCheck = (product) => {
    onProductSelect(product);
  };

  return (
    <PagedTable
      page={page}
      totalPages={totalPages}
      items={products}
      pagingNavigator={pagingNavigator}
    >
      {children}
      <ProductTable
        products={products}
        onProductSelect={handleProductCheck}
        selectedProducts={selectedProducts}
      />
    </PagedTable>
  );
};

export default ProductSearchAspect;
