import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@jsluna/table";

import CustomerClient from "../../services/CustomerClient";
import { LoadingState } from "../../services/http";
import "./Offers.scss";

const customerClient = new CustomerClient();

const ExpiredOffersView = ({ customerId }) => {
  const [offers, setOffers] = useState([]);
  const [loadingState, setLoadingState] = useState(LoadingState.PENDING);

  const COLUMN_NAMES = {
    COLUMN_OFFER: "Offer",
    COLUMN_POINTS: "Points",
    COLUMN_VALID_FROM: "Valid From",
    COLUMN_EXPIRED: "Expired",
    COLUMN_STATE: "State",
    COLUMN_STATUS: "Status",
  };

  const columns = Object.values(COLUMN_NAMES);

  useEffect(() => {
    getExpiredOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const getExpiredOffers = () => {
    customerClient
      .getExpiredOrUsedOffers(customerId)
      .then(({ data }) => {
        setOffers(data.offers);
        setLoadingState(LoadingState.SUCCESS);
      })
      .catch((e) => {
        console.error(e);
        setLoadingState(LoadingState.FAILED);
      });
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  let content;

  switch (loadingState) {
    case LoadingState.SUCCESS:
      content =
        offers.length > 0 ? (
          <TableContainer responsive>
            <TableHeader>
              <TableHeaderRow>
                {columns.map((column) => (
                  <TableHeaderCell key={column}>{column}</TableHeaderCell>
                ))}
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {offers.map((offer, key) => (
                <TableRow key={key}>
                  <TableCell label={COLUMN_NAMES.COLUMN_OFFER}>
                    <div className="image-description">
                      <img src={offer.imgUrl} className="small-image" alt="" />
                      <div className="content">
                        <div className="title">{offer.title}</div>
                        <div className="sub-header">{offer.description}</div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell label={COLUMN_NAMES.COLUMN_POINTS}>
                    {offer.points}
                  </TableCell>
                  <TableCell label={COLUMN_NAMES.COLUMN_VALID_FROM}>
                    {formattedDate(offer.validFrom)}
                  </TableCell>
                  <TableCell label={COLUMN_NAMES.COLUMN_EXPIRED}>
                    {formattedDate(offer.expires)}
                  </TableCell>
                  <TableCell label={COLUMN_NAMES.COLUMN_STATE}>
                    {offer.state}
                  </TableCell>
                  <TableCell label={COLUMN_NAMES.COLUMN_STATUS}>
                    {offer.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        ) : (
          <span>No expired offers.</span>
        );
      break;
    case LoadingState.PENDING:
      content = "Loading expired offers...";
      break;
    default:
      content = "Failed to load expired offers.";
      break;
  }

  return <div>{content}</div>;
};

ExpiredOffersView.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default ExpiredOffersView;
