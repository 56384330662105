import React, { useCallback, useEffect, useState } from "react";

import ProductClient from "../../services/ProductClient";
import PagedTable from "./PagedTable";
import ProductTable from "./ProductTable";

const productClient = new ProductClient();
const WAIT_INTERVAL = 500;

const GroupDetailAspect = ({ group, children }) => {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [products, setProducts] = useState([]);

  // Function to load products for a given group
  const loadGroupProducts = useCallback(
    (page = 0) => {
      productClient.getGroupProducts(group.id, page).then((data) => {
        setPage(data.currentPage);
        setTotalPages(data.totalPages);
        setProducts(data.products);
      });
    },
    [group.id]
  );

  // Function to handle paging navigation
  const pagingNavigator = (amount) => {
    if (page + amount >= 0 && page + amount < totalPages) {
      setTimeout(() => {
        loadGroupProducts(page + amount);
      }, WAIT_INTERVAL);
    }
  };

  // Load products when the component mounts or when the group changes
  useEffect(() => {
    loadGroupProducts();
  }, [loadGroupProducts]);

  return (
    <div>
      <PagedTable
        page={page}
        totalPages={totalPages}
        items={products}
        pagingNavigator={pagingNavigator}
      >
        {children}
        <ProductTable
          products={products}
          selectedProducts={[]}
          onProductSelect={() => {}}
        />
      </PagedTable>
    </div>
  );
};

export default GroupDetailAspect;
