import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { formatNectarNumber } from "../../common/formatNectarNumber";
import CustomerPointsManager from "./CustomerPointsManager";

const CustomerBrief = ({ customer }) => {
  const [cardNumberChanged, setCardNumberChanged] = useState(false);

  const baseStyle = {
    transition: "all 0.5s ease, all 0.5s ease-out",
  };

  const updatedStyle = {
    ...baseStyle,
    border: "2px solid green",
    background: "#caffbf",
  };

  useEffect(() => {
    if (customer?.cardNumber) {
      setCardNumberChanged(true);
      const timer = setTimeout(() => setCardNumberChanged(false), 2000);

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or update
    }
  }, [customer.cardNumber]); // Only run when cardNumber changes

  return (
    <GridWrapper>
      <GridItem>
        <span style={cardNumberChanged ? updatedStyle : baseStyle}>
          <h3>Nectar Card Number: {formatNectarNumber(customer.cardNumber)}</h3>
        </span>
        {customer.accountStatus === "locked" && (
          <span style={{ color: "#c00" }}>
            Locked in Identity (Trigger password reset to unlock)
          </span>
        )}
      </GridItem>
      <GridItem>
        <CustomerPointsManager customerBalance={customer.balance} />
      </GridItem>
      <GridItem>
        <b>Postcode:</b> {customer.postcode}
      </GridItem>
      <GridItem>
        <Card
          style={{
            border: "1px solid #d8d8d8",
            borderRadius: "3px",
            margin: "1rem 0 1rem 0",
          }}
        >
          <div className="ln-c-table-container">
            <table className="ln-c-table">
              <tbody className="ln-c-table__body">
                <tr className="ln-c-table__row">
                  <td className="customCol ln-c-table__cell">Customer Id:</td>
                  <td className="ln-c-table__cell">{customer.id}</td>
                </tr>
                <tr className="ln-c-table__row">
                  <td className="customCol ln-c-table__cell">
                    External Handle:
                  </td>
                  <td className="ln-c-table__cell">
                    {customer.externalHandle}
                  </td>
                </tr>
                <tr className="ln-c-table__row">
                  <td className="customCol ln-c-table__cell">
                    JS Identity Id:
                  </td>
                  <td className="ln-c-table__cell">{customer.identityId}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      </GridItem>
    </GridWrapper>
  );
};

CustomerBrief.propTypes = {
  customer: PropTypes.shape({
    cardNumber: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    externalHandle: PropTypes.string.isRequired,
    identityId: PropTypes.string.isRequired,
    accountStatus: PropTypes.string,
    balance: PropTypes.number,
    id: PropTypes.string,
  }),
};

export default CustomerBrief;
