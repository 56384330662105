export const InitialState = () => {
  return {
    skuId: null,
    itemUnitMetric: "EACH",
    category: "vegetable",
    portionsPerMetric: null,
    targetSkuIds: [],
  };
};

export const ItemUnitMetric = [
  { label: "Each", value: "EACH" },
  { label: "Grams", value: "GRAMS" },
];

export const Category = [
  { label: "Other", value: "other" },
  { label: "Vegetable", value: "vegetable" },
  { label: "Fruit", value: "fruit" },
];
