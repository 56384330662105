import React, { useState } from "react";

const GroupRow = ({ group, isSelected, onCheck, onSelect }) => {
  const [hover, setHover] = useState(false);

  // const handleCheck = () => {
  //   onCheck(group);
  // };

  const handleRowSelect = () => {
    onSelect(group);
  };

  return (
    <tr
      className="ln-c-table__row"
      style={{
        backgroundColor: isSelected ? "silver" : hover ? "#FFFAF7" : "white",
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <td className="ln-c-table__cell" onClick={handleRowSelect}>
        {group.name}
      </td>
      <td className="ln-c-table__cell" onClick={handleRowSelect}>
        {group.status}
      </td>
    </tr>
  );
};

export default GroupRow;
