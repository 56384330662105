import React from "react";

import { ArrowFirst, ArrowLast, ArrowLeft, ArrowRight } from "@jsluna/icons";

const PagedTable = ({ pagingNavigator, page, items, totalPages, children }) => {
  return (
    <div>
      <ArrowFirst
        size="large"
        name="fast backward"
        onClick={() => pagingNavigator(-page)}
        disabled={page === 0}
      />
      <ArrowLeft
        size="large"
        name="backward"
        onClick={() => pagingNavigator(-1)}
        disabled={page === 0}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {!!items.length && (
        <span>
          Page {page + 1} of {totalPages}{" "}
        </span>
      )}
      <ArrowRight
        size="large"
        name="forward"
        onClick={() => pagingNavigator(1)}
        disabled={totalPages <= page + 1}
        style={{ marginLeft: "20px" }}
      />
      <ArrowLast
        size="large"
        name="fast forward"
        onClick={() => pagingNavigator(totalPages - (page + 1))}
        disabled={totalPages <= page + 1}
        style={{ marginRight: "20px" }}
      />
      <div style={{ float: "right" }}>{children[0]}</div>
      {children.slice(1)}
    </div>
  );
};

export default PagedTable;
