import { isEmpty, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Alert, AlertIcon } from "@jsluna/alert";
import {
  Button,
  FilledButton,
  LinkButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, SearchField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Cancel, ErrorCircle, List, Plus, Search, Upload } from "@jsluna/icons";

import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import SkuClient from "../../services/SkuClient";
import SkuEditor from "./SkuEditor";

const skuClient = new SkuClient();

const viewStates = Object.freeze({
  EDIT: Symbol("Edit"),
  LIST: Symbol("List"),
});

const SkuView = () => {
  const [skus, setSkus] = useState([]);
  const [error, setError] = useState(undefined);
  const [viewState, setViewState] = useState(viewStates.LIST);
  const [currentSkuId, setCurrentSkuId] = useState(null);
  const [searchSkuId, setSearchSkuId] = useState("");
  const [searchTargetSkuId, setSearchTargetSkuId] = useState("");
  const assetUploadInputRef = useRef(null);
  const navigate = useNavigate();

  const isMEEditor = HasRequiredRoleAccess("marketing-events");

  useEffect(() => {
    reloadSkus();
    // eslint-disable-next-line
  }, []);

  const reloadSkus = () => {
    clearSearchFields();
    skuClient
      .fetchSkus()
      .then(({ data }) => {
        setSkus(data);
      })
      .catch((err) => {
        setError("Failed to load skus list: " + err);
      });
  };

  const clearSearchFields = () => {
    setSearchSkuId("");
    setSearchTargetSkuId("");
  };

  const deleteSku = (id) => {
    skuClient.deleteSku(id).then(() => {
      toast.success(`Deleted Sku ${id ? ` '${id}'` : ""}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 2000,
      });
      reloadSkus();
    });
  };

  const onEditClick = (id) => {
    setCurrentSkuId(id);
    setViewState(viewStates.EDIT);
    navigate("/skus");
  };

  const onCreateClick = () => {
    clearSearchFields();
    setCurrentSkuId(null);
    setViewState(viewStates.EDIT);
    navigate("/skus");
  };

  const currentView = () => {
    const props = {
      onEditClick,
      deleteSku,
    };

    switch (viewState) {
      case viewStates.EDIT:
        return (
          <SkuEditor
            key={currentSkuId}
            currentSkuId={currentSkuId}
            close={() => {
              setViewState(viewStates.LIST);
              reloadSkus();
            }}
          />
        );
      case viewStates.LIST:
        return (
          <div>
            <GridWrapper style={{ padding: "1.5rem 0 0 0" }}>
              <GridItem size={{ xs: "1/2" }}>
                <FilledButton onClick={onCreateClick} disabled={!isMEEditor}>
                  <Plus />
                  &nbsp;&nbsp;Create Sku
                </FilledButton>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <OutlinedButton disabled={!isMEEditor} onClick={onUploadClick}>
                  <Upload /> &nbsp;&nbsp; Bulk Upload
                </OutlinedButton>
                <input
                  id="fileUpload"
                  type="file"
                  hidden
                  onChange={onUpload}
                  ref={assetUploadInputRef}
                />
              </GridItem>
              <GridItem size={{ xs: "1/2" }}>
                <GridItem size={{ xs: "1/2" }}>
                  <SearchField
                    name="searchBySku"
                    placeholder="Search by Sku Id"
                    label="Search By SKU"
                    hideLabel
                    value={searchSkuId || ""}
                    onChange={(e) => setSearchSkuId(e.target.value)}
                    hasAction
                    action={
                      <Button>
                        <span className="ln-u-visually-hidden">
                          Search by SKU
                        </span>
                        <Search />
                      </Button>
                    }
                  />
                </GridItem>
                <GridItem size={{ xs: "1/2" }}>
                  <SearchField
                    name="searchByTargetSku"
                    placeholder="Search by Target Sku Id"
                    label="Search By Target SKU Id"
                    hideLabel
                    value={searchTargetSkuId || ""}
                    onChange={(e) => setSearchTargetSkuId(e.target.value)}
                    hasAction
                    action={
                      <Button>
                        <span className="ln-u-visually-hidden">
                          Search by Target SKU Id
                        </span>
                        <Search />
                      </Button>
                    }
                  />
                </GridItem>
              </GridItem>
            </GridWrapper>

            <SkusList
              skuData={skus}
              searchSkuId={searchSkuId}
              searchTargetSkuId={searchTargetSkuId}
              {...props}
            />
          </div>
        );
      default:
        return <div />;
    }
  };
  const onUploadClick = (e) => {
    assetUploadInputRef.current.value = null;
    assetUploadInputRef.current.click();
  };

  const onUpload = (e) => {
    const files = e.target.files;
    const file = files && files[0];
    if (file) {
      skuClient
        .getFileUploadUrl(file.name)
        .then((url) => uploadFile(url.data, file))
        .catch(() => alert("File upload error"));
    }
  };

  const uploadFile = (url, file) => {
    skuClient
      .uploadFile(url, file)
      .then(() =>
        alert("The Sku file was uploaded and will be processed shortly.")
      )
      .catch(() => alert("File upload error"));
  };

  if (!skus && !error) {
    return null;
  }

  return (
    <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
      <GridWrapper>
        <GridItem size={{ xs: "1" }}>
          <span>
            <List />
            &nbsp;&nbsp;<Label>Skus</Label>
          </span>
        </GridItem>
        <GridItem>{currentView()}</GridItem>
      </GridWrapper>
    </Card>
  );
};

const SkusList = ({
  skuData,
  searchSkuId,
  searchTargetSkuId,
  onEditClick,
  deleteSku,
}) => {
  if (skuData.error) return <ErrorLoadingSku message={skuData.error} />;
  skuData.sort(compareSkus);
  let filteredSkus = skuData.filter((s) =>
    s.skuId.toLowerCase().startsWith(searchSkuId.toLowerCase())
  );
  filteredSkus = filteredSkus.filter(
    (s) =>
      searchTargetSkuId === "" ||
      (s.targetSkuIds !== undefined &&
        s.targetSkuIds.find((item) =>
          item.toLowerCase().startsWith(searchTargetSkuId.toLowerCase())
        ))
  );
  return (
    <p>
      <br />
      <i>
        <strong>Number of skus: {filteredSkus.length}</strong>
      </i>
      <SkuTable>
        <SkuTableHeader />
        <SkuTableBody>
          {map(filteredSkus, (p) => (
            <SkuRow
              deleteSku={deleteSku}
              sku={p}
              key={p.skuId}
              onClick={() => {
                onEditClick(p.skuId);
              }}
            />
          ))}
        </SkuTableBody>
      </SkuTable>
    </p>
  );
};

function compareSkus(a, b) {
  return a.skuId < b.skuId ? -1 : a.skuId > b.skuId ? 1 : 0;
}

const SkuTableHeader = () => (
  <thead className="ln-c-table__header">
    <tr className="ln-c-table__row ln-c-table__header-row">
      {[
        "Sku Id",
        "Product Category",
        "Unit Metric",
        "Portions per metric",
        "Targets Sku id",
        "",
      ].map((header, idx) => (
        <th className="ln-c-table__header-cell customCol" key={idx}>
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

const SkuTable = (props) => (
  <div style={{ paddingTop: "1.5rem" }}>
    <table className="ln-c-table" celled striped>
      {props.children}
    </table>
  </div>
);
const SkuTableBody = (props) => (
  <tbody className="ln-c-table__body">{props.children}</tbody>
);

const SkuRow = ({ sku, onClick, deleteSku }) => {
  const isMEEditor = HasRequiredRoleAccess("marketing-events");
  const errors = [];
  return (
    <tr className="ln-c-table__row ln-c-table__header-row">
      <td className="ln-c-table__cell">
        <h4>
          <LinkButton onClick={onClick} disabled={!isMEEditor}>
            {sku.skuId ? sku.skuId : "Undefined"}
            {isEmpty(errors) ? null : <span>&nbsp; Invalid sku</span>}
          </LinkButton>
        </h4>
      </td>
      <td className="ln-c-table__cell">{sku.category}</td>
      <td className="ln-c-table__cell">{sku.itemUnitMetric}</td>
      <td className="ln-c-table__cell">{sku.portionsPerMetric}</td>
      <td className="ln-c-table__cell">{sku.targetSkuIds.join(", ")}</td>
      <td className="ln-c-table__cell" collapsing>
        <OutlinedButton
          onClick={() => {
            if (window.confirm("Are you sure you wish to delete this sku?"))
              deleteSku(sku.skuId);
          }}
          disabled={!isMEEditor}
        >
          <Cancel />
        </OutlinedButton>
      </td>
    </tr>
  );
};

const ErrorLoadingSku = ({ message }) => (
  <GridItem size="1/1" className="ln-u-margin-top*3 ln-u-margin-bottom">
    <Alert variant="error">
      <AlertIcon>
        <ErrorCircle aria-label="Error" role="img" />
      </AlertIcon>
      {message}
    </Alert>
  </GridItem>
);

export default SkuView;
