import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { FilledButton } from "@jsluna/button";
import {
  TableBody,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from "@jsluna/table";

import LedgerClient from "../../services/LedgerClient";
import { LoadingState } from "../../services/http";
import TransactionRow from "./TransactionRow";

const ledgerClient = new LedgerClient();

const TransactionsView = ({ cardNumber }) => {
  const [transactions, setTransactions] = useState([]);
  const [loadingState, setLoadingState] = useState(LoadingState.PENDING);
  const [offset, setOffset] = useState(0);
  const [hasAllTransactions, setHasAllTransactions] = useState(false);
  const limit = 20;

  const collectorID = cardNumber.substr(cardNumber.length - 11);

  const COLUMN_NAMES = {
    COLUMN_DATE: "Date",
    COLUMN_DESCRIPTION: "Description",
    COLUMN_STORE: "Store",
    COLUMN_AMOUNT: "Amount",
  };

  const columns = Object.values(COLUMN_NAMES);

  const getTransactions = () => {
    ledgerClient
      .getTransactions(collectorID, offset, limit)
      .then(({ data }) => {
        setTransactions((prevTransactions) =>
          data.items ? [...prevTransactions, ...data.items] : prevTransactions
        );
        setOffset((prevOffset) => prevOffset + limit);
        setHasAllTransactions(data.offset + limit >= data.total);
        setLoadingState(LoadingState.SUCCESS);
      })
      .catch((e) => {
        console.error(e);
        setLoadingState(LoadingState.FAILED);
      });
  };

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    switch (loadingState) {
      case LoadingState.SUCCESS:
        return transactions.length > 0 ? (
          <div>
            <TableContainer responsive>
              <TableHeader>
                <TableHeaderRow>
                  {columns.map((column) => (
                    <TableHeaderCell key={column}>{column}</TableHeaderCell>
                  ))}
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {transactions.map((row, i) => (
                  <TransactionRow key={i} transaction={row} />
                ))}
              </TableBody>
            </TableContainer>
            {!hasAllTransactions && (
              <FilledButton
                onClick={getTransactions}
                className="ln-u-margin-top*3"
              >
                Load more
              </FilledButton>
            )}
          </div>
        ) : (
          <span>No transactions.</span>
        );
      case LoadingState.PENDING:
        return "Loading transactions...";
      default:
        return "Failed to load transactions.";
    }
  };

  return <div>{renderContent()}</div>;
};

TransactionsView.propTypes = {
  cardNumber: PropTypes.string.isRequired,
};

export default TransactionsView;
