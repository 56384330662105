import PropTypes from "prop-types";
import React from "react";

import GroupRow from "./GroupRow";

const GroupTable = ({ groups, onRowCheck, onRowSelect, selectedGroups }) => {
  const handleOnRowCheck = (group) => {
    onRowCheck(group);
  };

  const handleOnRowSelect = (group) => {
    onRowSelect(group);
  };

  const groupSelected = (grp) => {
    return selectedGroups.some((x) => x.id === grp.id);
  };

  const tableBody = groups.map((group, i) => (
    <GroupRow
      key={i}
      group={group}
      onCheck={handleOnRowCheck}
      onSelect={handleOnRowSelect}
      isSelected={groupSelected(group)}
    />
  ));

  return (
    <table className="ln-c-table">
      <thead className="ln-c-table__header">
        <tr className="ln-c-table__row ln-c-table__header-row">
          {/* <th className="ln-c-table__header-cell customCol">Select</th> */}
          <th className="ln-c-table__header-cell customCol">Name</th>
          <th className="ln-c-table__header-cell customCol">Status</th>
        </tr>
      </thead>
      <tbody className="ln-c-table__body">{tableBody}</tbody>
    </table>
  );
};

GroupTable.propTypes = {
  groups: PropTypes.array.isRequired,
  onRowCheck: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  selectedGroups: PropTypes.array.isRequired,
};

export default GroupTable;
