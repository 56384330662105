import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FilledButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { TabLink, TabPanel, Tabs } from "@jsluna/tabs";

import WelcomeLogo from "../../assets_V2/WelcomeLogo.png";
import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import CustomerClient from "../../services/CustomerClient";
import { LoadingState } from "../../services/http";
import NotFoundView from "../error/NotFoundView";
import CustomerBrief from "./CustomerBrief";
import ExpiredOffersView from "./ExpiredOffersView";
import OffersView from "./OffersView";
import TransactionsView from "./TransactionView";

const customerClient = new CustomerClient();

const CustomerDetailsView = () => {
  const { externalHandle } = useParams();
  const [customer, setCustomer] = useState(undefined);
  const [loadingState, setLoadingState] = useState(LoadingState.PENDING);
  const [activeItem, setActiveItem] = useState("offers");
  const isOA = HasRequiredRoleAccess("allocateoffer");
  const navigate = useNavigate();

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalHandle]);

  const getCustomer = () => {
    customerClient
      .getCustomer(externalHandle)
      .then(({ data }) => {
        setCustomer(data);
        setLoadingState(LoadingState.SUCCESS);
      })
      .catch(() => {
        setLoadingState(LoadingState.FAILED);
      });
  };

  const tabs = [
    { name: "Offers", key: "offers" },
    { name: "Points Awarded", key: "transactions" },
    { name: "Expired Offers", key: "expired" },
  ];

  return (
    <>
      {loadingState === LoadingState.SUCCESS ? (
        <div>
          <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
            <GridWrapper verticalAlign="middle">
              <GridItem>
                <CustomerBrief customer={customer} />
              </GridItem>
              {!isOA && (
                <GridItem>
                  <Card
                    style={{
                      border: "1px solid #d8d8d8",
                      borderRadius: "3px",
                      margin: "1rem 0 1rem 0",
                    }}
                  >
                    <FilledButton
                      onClick={() =>
                        navigate("/allocateoffer", {
                          state: { CsData: customer },
                        })
                      }
                      className="ln-u-margin-top ln-u-margin-bottom"
                      type="submit"
                    >
                      Allocate Offer
                    </FilledButton>
                  </Card>
                </GridItem>
              )}
              <GridItem>
                <div>
                  <Tabs type="pill">
                    {tabs.map((tab) => (
                      <TabLink
                        key={tab.key}
                        onClick={() => setActiveItem(tab.key)}
                        active={activeItem === tab.key}
                      >
                        {tab.name}
                      </TabLink>
                    ))}
                  </Tabs>
                  <TabPanel>
                    <Card
                      style={{
                        border: "1px solid #d8d8d8",
                        borderRadius: "3px",
                        margin: "1rem 0",
                      }}
                    >
                      <h3>
                        {tabs.find((tab) => activeItem === tab.key).name}{" "}
                        Section
                      </h3>
                      {activeItem === "offers" && (
                        <OffersView
                          offers={customer.offers}
                          customer={customer}
                          reloadCustomer={getCustomer}
                          isOA={isOA}
                        />
                      )}
                      {activeItem === "transactions" && (
                        <TransactionsView cardNumber={customer.cardNumber} />
                      )}
                      {activeItem === "expired" && (
                        <ExpiredOffersView customerId={customer.id} />
                      )}
                    </Card>
                  </TabPanel>
                </div>
              </GridItem>
            </GridWrapper>
          </Card>
        </div>
      ) : loadingState === LoadingState.PENDING ? (
        <div
          style={{
            position: "absolute",
            display: "flex",
            width: "100%",
            height: "85vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridWrapper
            className="ln-o-grid ln-o-grid--middle"
            horizontalAlign="center"
            verticalAlign="middle"
          >
            <GridItem className="ln-u-text-align-center" size={{ md: "1" }}>
              <div className="ln-u-soft">
                <img
                  src={WelcomeLogo}
                  className="logoAnimate"
                  alt=""
                  width="100"
                  height="100"
                />
              </div>
            </GridItem>
            <GridItem
              className="welcomeMsgTxt ln-u-text-align-center"
              size={{ md: "1" }}
            >
              <h3>Loading...</h3>
            </GridItem>
          </GridWrapper>
        </div>
      ) : (
        <NotFoundView />
      )}
    </>
  );
};

export default CustomerDetailsView;
