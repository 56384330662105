import { isEmpty } from "lodash";
import React from "react";

import { Label } from "@jsluna/form";
import { GridItem } from "@jsluna/grid";
import { MobilePhone } from "@jsluna/icons";

import { PlaceholderImage } from "../../pod";

const PodPreview = ({ pod }) => {
  const getFirstFooterImage = () => {
    const footerImages = pod.footer?.data?.brands;
    if (!isEmpty(footerImages))
      return (
        <img
          src={footerImages[0].imageUrl}
          style={{ maxHeight: "3.9rem", width: "6rem", objectFit: "contain" }}
          alt={footerImages[0].altText}
        />
      );
  };

  return (
    <GridItem>
      <GridItem>
        <GridItem size="1/10">
          <MobilePhone />
        </GridItem>
        <GridItem size="1/2">
          <span>
            <strong>
              <Label>Preview</Label>
            </strong>
          </span>
        </GridItem>
      </GridItem>
      <br />
      <GridItem>
        <div
          className="podPreview"
          style={{
            background: pod.body?.colors?.dark?.backgroundColor,
            color:
              pod.body?.colors?.dark?.backgroundColor === "#8223FA" && "white",
          }}
        >
          <div>
            <div
              className="previewTitle"
              style={{ color: pod.header?.colors?.dark?.foregroundColor }}
            >
              {pod.header?.data?.title}
            </div>
            <div
              className="previewSubText"
              style={{ color: pod.header?.colors?.dark?.foregroundColor }}
            >
              {pod.header?.data?.subtitle}
            </div>
          </div>
          <div>
            {pod.body && pod.body.type && pod.body.type === "IMAGE" ? (
              <div>
                <img
                  src={
                    pod.body?.data?.imageUrl
                      ? pod.body.data.imageUrl
                      : PlaceholderImage
                  }
                  alt={pod.body.data.accessibilityText}
                  style={{ width: "100%" }}
                />
              </div>
            ) : pod.images && pod.images.length > 0 ? (
              pod.images.map((image) => (
                <div className="previewTitledLayout">
                  <img
                    src={image.imageUrl}
                    alt={image.name}
                    style={{ width: "100%" }}
                  />
                </div>
              ))
            ) : (
              <img
                src={PlaceholderImage}
                alt={"placeholder"}
                style={{ width: "100%" }}
              />
            )}
            {!!pod.footer?.data?.brands && pod.footer.data.brands.length > 1 ? (
              <div className="previewMultiFooter">
                {pod.footer.data.brands.slice(0, 3).map((image) => (
                  <img
                    src={image.imageUrl}
                    alt={image.altText}
                    style={{ height: "2.25rem" }}
                  />
                ))}
              </div>
            ) : (
              <div className="previewFooter">{getFirstFooterImage()}</div>
            )}
          </div>
        </div>
      </GridItem>
    </GridItem>
  );
};

export default PodPreview;
