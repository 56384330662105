import React, { useCallback, useEffect, useState } from "react";

import ProductClient from "../../services/ProductClient";
import GroupTable from "./GroupTable";
import PagedTable from "./PagedTable";

const productClient = new ProductClient();
const WAIT_INTERVAL = 500;

const GroupListAspect = ({
  selectedGroups,
  onGroupCheck,
  onGroupSelect,
  children,
}) => {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [groups, setGroups] = useState([]);

  // Function to load groups from the server
  const loadGroups = useCallback((page = 0) => {
    productClient.listGroups(page).then((data) => {
      setPage(data.currentPage);
      setTotalPages(data.totalPages);
      setGroups(data.groups);
    });
  }, []);

  // Function to handle paging navigation
  const pagingNavigator = (amount) => {
    if (page + amount >= 0 && page + amount < totalPages) {
      setTimeout(() => {
        loadGroups(page + amount);
      }, WAIT_INTERVAL);
    }
  };

  // Handle group check (with locked status warning)
  const handleGroupCheck = (group) => {
    if (group.status === "LOCKED") {
      alert("This group is locked.");
      return;
    }
    onGroupCheck(group);
  };

  // Load groups when the component mounts
  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  return (
    <PagedTable
      page={page}
      totalPages={totalPages}
      items={groups}
      pagingNavigator={pagingNavigator}
    >
      {children}
      <GroupTable
        groups={groups}
        onRowCheck={handleGroupCheck}
        onRowSelect={onGroupSelect}
        selectedGroups={selectedGroups}
      />
    </PagedTable>
  );
};

export default GroupListAspect;
